<template>
  <div
    class="app absolute inset-0 flex flex-nowrap justify-start xl:justify-center tall:items-center h-stretch bg-fixed bg-no-repeat bg-center bg-cover bg-fade before:absolute before:w-full before:h-full before:transition-opacity before:duration-300 before:ease-out before:delay-75 before:bg-fade"
    :class="{
      'before:opacity-100': !showBackground,
      'before:opacity-0': showBackground,
    }"
    :style="{
      backgroundImage: `url(${backgroundUrl})`,
    }"
    @dragenter="handleDragAndDrop"
    @dragover="handleDragAndDrop"
    @drop="handleDragAndDrop"
  >
    <ToastToaster />
    <NotificationRequester />
    <div
      class="relative flex-initial min-w-[1200px] w-full 2xl:w-[1920px] min-h-[800px] max-w-full p-4 h-screen tall:h-[1080px]"
    >
      <RouterView name="header" v-slot="{ Component }">
        <component :is="Component" class="absolute inset-4 bottom-0" />
      </RouterView>
      <RouterView v-slot="{ Component }">
        <component :is="Component" class="view" />
      </RouterView>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { computedAsync } from "@vueuse/core";
import { RouterView } from "vue-router";

// util
import { background } from "@/util/image";

// state
import { useAuth } from "@/state/auth";
import { api } from "@/util/fetch";
import { computed } from "vue";

export default defineComponent({
  name: "App",
});
</script>
<script lang="ts" setup>
// components
import ToastToaster from "@/components/core/toaster.vue";
import NotificationRequester from "@/components/core/notificationRequester.vue";

const { isLoggedIn } = useAuth();
const backgroundEvaluating = ref<boolean>(false);
const backgroundUrl = computedAsync<string | null>(
  (onCancel) => {
    if (!isLoggedIn.value) return background;
    const abortController = new AbortController();

    onCancel(() => abortController.abort());

    return api
      .get("user/background/", {
        signal: abortController.signal,
        // TODO uncomment headers object after the api has been changed
        // headers: {
        //   Accept: "image/*",
        // },
      })
      .then(async (response) => {
        if (response.status === 204) return background;
        const url = URL.createObjectURL(await response.blob());
        onCancel(() => URL.revokeObjectURL(url));
        return url;
      })
      .catch(() => background);
  },
  null,
  backgroundEvaluating,
);

const showBackground = computed<boolean>(
  () => !backgroundEvaluating.value && backgroundUrl.value !== null,
);
// cleanup after background changed
watch(backgroundUrl, (_, oldUrl) => {
  if (oldUrl) URL.revokeObjectURL(oldUrl);
});

// methods
function handleDragAndDrop(event: Event): void {
  const target = event.target as HTMLElement;
  if (
    (target.tagName !== "INPUT" ||
      (target as HTMLInputElement).type !== "file") &&
    !target.classList.contains("dropzone") &&
    !target.id.includes("dropzone")
  ) {
    event.preventDefault();
    if ((event as DragEvent).dataTransfer) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      (event as DragEvent).dataTransfer!.effectAllowed = "none";
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      (event as DragEvent).dataTransfer!.dropEffect = "none";
    }
  }
}
</script>
