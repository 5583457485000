<template>
  <Teleport to="#alerts">
    <div
      v-if="show"
      class="absolute left-0 top-0 w-screen h-screen bg-black/10 backdrop-blur-sm"
    >
      <div
        class="bg-white rounded-md shadow-xl mt-[5%] mx-auto px-4 py-2 w-max"
      >
        <h5 class="mb-1.5">Doc.digi wil notificaties laten zien</h5>

        <button
          type="button"
          class="button button-primary ml-auto mt-2"
          @click="onClick()"
        >
          Ok
        </button>
      </div>
    </div>
  </Teleport>
</template>
<script lang="ts">
import { defineComponent, ref } from "vue";
import { useEventBus } from "@vueuse/core";

// state
import { notificationKey } from "@/state/keys";

export default defineComponent({
  name: "NotificationRequester",
});
</script>
<script lang="ts" setup>
const bus = useEventBus(notificationKey);

const show = ref<boolean>(false);

bus.on((e) => {
  if (e === "request") {
    show.value = true;
  }
});

function onClick(): void {
  show.value = false;
  Notification.requestPermission()
    .then((permission) => {
      if (permission === "granted") {
        bus.emit("granted");
      } else {
        bus.emit("denied");
      }
    })
    .catch((err) => {
      console.error(err);
      bus.emit("denied");
    });
}
</script>
