<template>
  <div
    title="Avatar"
    class="avatar text-primary"
    :class="{
      'rounded-full': circle,
      'rounded-md': !circle,
    }"
  >
    <img v-if="$props.src" :src="$props.src" alt="avatar" class="avatarImage" />
    <UserIcon v-else class="avatarIcon" />
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "AvatarBox",
});
</script>
<script lang="ts" setup>
// components
import { UserIcon } from "@heroicons/vue/20/solid";

withDefaults(
  defineProps<{
    src?: string;
    size?: "mini" | "normal" | "big" | "huge" | "monstrous";
    circle?: boolean;
  }>(),
  {
    size: "normal",
    circle: true,
  },
);
</script>
