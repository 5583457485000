export const accessToken = {
  key: "accessToken",
  get(): string | null {
    const value = localStorage.getItem(this.key);
    if (!value || value === "undefined" || value === "null") return null;
    return value;
  },
  set(value: string): void {
    localStorage.setItem(this.key, value);
  },
  clear(): void {
    localStorage.removeItem(this.key);
  },
};

export const refreshToken = {
  key: "refreshToken",
  get(): string | null {
    const value = localStorage.getItem(this.key);
    if (!value || value === "undefined" || value === "null") return null;
    return value;
  },
  set(value: string): void {
    localStorage.setItem(this.key, value);
  },
  clear(): void {
    localStorage.removeItem(this.key);
  },
};

export const accessTokenExpireDate = {
  key: "accessTokenExpireDate",
  get(): number | null {
    const num = Number(localStorage.getItem(this.key));
    return isNaN(num) ? null : num;
  },
  set(value: number): void {
    localStorage.setItem(this.key, String(value));
  },
  clear(): void {
    localStorage.removeItem(this.key);
  },
};

export const refreshTokenExpireDate = {
  key: "refreshTokenExpireDate",
  get(): number | null {
    const num = Number(localStorage.getItem(this.key));
    return isNaN(num) ? null : num;
  },
  set(value: number): void {
    localStorage.setItem(this.key, String(value));
  },
  clear(): void {
    localStorage.removeItem(this.key);
  },
};
