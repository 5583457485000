// https://github.com/sindresorhus/ky#readme

import ky from "ky";
import camelCase from "lodash-es/camelCase";

import { injectToken } from "./kyHooks/token";

const base = ky.create({
  headers: {
    "X-Requested-With": "ky",
  },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function convertKeysToCamelCase(value: any): any {
  if (value === null) {
    return null;
  } else if (value === undefined) {
    return undefined;
  } else if (typeof value === "number") {
    return value;
  } else if (Array.isArray(value)) {
    return value.map(convertKeysToCamelCase);
  }
  return typeof value !== "object"
    ? value
    : Object.keys(value).reduce((prev, current) => {
        const newKey = camelCase(current);
        if (typeof value[current] === "object") {
          prev[newKey] = convertKeysToCamelCase(value[current]);
        } else {
          prev[newKey] = value[current];
        }
        return prev;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      }, {} as any);
}

export const api = base.extend({
  prefixUrl: new URL("api/v1", API_BASE_URL),
  hooks: {
    beforeRequest: [injectToken],
  },
  parseJson: (text) =>
    JSON.parse(text, (_, value) => convertKeysToCamelCase(value)),
});

export default base;
