<template>
  <nav
    class="flex justify-between items-stretch flex-wrap h-12 mb-4"
    :class="$props.class"
  >
    <div
      class="flex flex-initial justify-start flex-wrap items-center max-h-full"
    >
      <!-- navbar start items -->
      <RouterLink title="Home" to="/" class="ml-2">
        <img :src="logoLong" alt="logo" class="h-12" />
      </RouterLink>
    </div>
    <div class="flex flex-1 flex-wrap justify-center items-center max-h-full">
      <!-- navbar center items -->
    </div>
    <div
      class="flex flex-initial justify-end flex-wrap items-center max-h-full"
    >
      <!-- navbar end items -->
      <!-- <h6
        class="tracking-wide font-bold font-display text-base px-1 cursor-default"
      ></h6> -->
      <div
        title="Credits"
        class="floating flex flex-nowrap items-center w-[74px] h-8 py-1 pr-1 pl-2.5 !rounded-full mr-2"
      >
        <h6
          class="flex-1 text-xs leading-none font-display tracking-wide cursor-default"
          :class="{
            'text-danger': user && user?.creditBalance < 1,
          }"
        >
          <AnimatedNumber :value="credits" :max="999" />
        </h6>
        <CheckBadgeIcon
          class="flex-initial w-5 h-5 transition-colors duration-150 ease-in-out text-stroke"
          :class="{
            '!text-danger': user && user.creditBalance < 1,
            '!text-success': user && user.creditBalance > 19,
          }"
        />
      </div>
      <Dropdown
        origin="right"
        :width-modifier="3.5"
        :y-offset="1"
        :model-value="open"
        @update:model-value="
          (value) => {
            if (user) open = value;
          }
        "
      >
        <template #trigger="{ setRef }">
          <button
            :ref="setRef"
            title="Menu"
            type="button"
            class="button relative flex flex-nowrap items-center h-full px-3 group/userButton"
            :class="{
              'button-active': open,
            }"
          >
            <AvatarBox class="floating !rounded-full border-transparent" />
            <div
              class="absolute flex flex-nowrap justify-center items-center w-8 h-8 !m-0 border overflow-hidden rounded-full group-hover/userButton:bg-white"
              :class="{
                'bg-transparent border-transparent': !open,
                'bg-white border-white': open,
              }"
            >
              <ChevronDownIcon
                class="w-5 h-5 text-transparent group-hover/userButton:text-stroke mt-0.5"
                :class="{
                  '!text-stroke': open,
                }"
              />
            </div>
          </button>
        </template>
        <template #header>
          <div class="dropdownHeader px-3 pt-2 pb-4">
            <h5 class="text-sm font-bold font-display break-all">
              {{ user?.fullName }}
            </h5>
            <h6
              class="text-xs leading-tight tracking-wider break-all indent-0.5"
            >
              {{ user?.organisation.name }}
            </h6>
          </div>
        </template>
        <template #items>
          <RouterLink class="dropdownItem" to="/user/self" tabindex="0">
            <div
              class="flex flex-nowrap items-center w-full h-full px-3"
              @click="onClickSettings"
            >
              Instellingen
            </div>
          </RouterLink>
        </template>
        <template #footer>
          <div class="dropdownFooter">
            <button
              type="button"
              class="dropdownItem px-3"
              tabindex="0"
              @click="onClickLogout"
            >
              Afmelden
            </button>
          </div>
        </template>
      </Dropdown>
    </div>
  </nav>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";

// state
import { useAuth } from "@/state/auth";
import { useUser } from "@/state/user";

// util
import { logoLong } from "@/util/image";
import { toast } from "@/util/toast";

import { useRouter } from "vue-router";

export default defineComponent({
  name: "CoreNavbar",
});
</script>
<script lang="ts" setup>
// components
import AvatarBox from "@/components/avatar.vue";
import Dropdown from "@/components/dropdown.vue";
import { CheckBadgeIcon, ChevronDownIcon } from "@heroicons/vue/20/solid";
import AnimatedNumber from "@/components/animated/animatedNumber.vue";

defineProps<{
  class?: string | { [key: string]: boolean };
}>();

const { send } = useAuth();

const user = useUser();

const router = useRouter();

const open = ref(false);

const credits = computed(() =>
  user.value ? Math.max(user.value.creditBalance, 0) : 0,
);

// methods
function onClickLogout(): void {
  open.value = false;
  send("LOG_OUT");
  router.push("login");
}

function onClickSettings(): void {
  open.value = false;
}
</script>
