<template>
  {{ tweened.number.toFixed(0)
  }}{{
    $props.max !== undefined &&
    $props.max > 1 &&
    $props.value > $props.max &&
    tweened.number === $props.max
      ? "+"
      : ""
  }}
</template>
<script lang="ts">
import { defineComponent, reactive, watch } from "vue";
import { gsap } from "gsap";

export default defineComponent({
  name: "AnimatedNumber",
});
</script>
<script lang="ts" setup>
const props = defineProps<{
  value: number;
  max?: number;
}>();

const tweened = reactive({
  number: isNaN(props.value) ? 0 : props.value,
});

watch(
  () => props.value,
  (n) => {
    gsap.to(tweened, {
      duration: 0.5,
      number:
        props.max !== undefined && props.max > 0 && n > props.max
          ? props.max
          : n,
    });
  },
);
</script>
