<template>
  <template v-if="$slots.trigger">
    <slot name="trigger" :set-ref="setRef"></slot>
    <Teleport to="#dropdowns">
      <div
        v-if="modelValue"
        ref="dropdown"
        class="dropdown"
        :class="$attrs.class"
        :style="{
          top: 'calc(' + bottom + 'px + 0.25rem * ' + yOffset + ')',
          left:
            origin === 'left'
              ? left + 'px'
              : `${right - width * widthModifier}px`,
          minWidth: width * widthModifier + 'px',
          width: width * widthModifier + 'px',
        }"
      >
        <slot name="header"></slot>
        <slot name="items"></slot>
        <slot name="footer"></slot>
      </div>
    </Teleport>
  </template>
</template>

<script lang="ts">
import { ref, defineComponent, Teleport, ComponentPublicInstance } from "vue";
import {
  onClickOutside,
  templateRef,
  useElementBounding,
  useEventListener,
  whenever,
} from "@vueuse/core";

export default defineComponent({
  name: "DropDown",
  inheritAttrs: false,
});
</script>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    modelValue: boolean;
    origin?: "left" | "right";
    widthModifier?: number;
    yOffset?: number;
  }>(),
  {
    origin: "left",
    widthModifier: 1,
    yOffset: 0,
  },
);

const emit = defineEmits<{
  (e: "update:modelValue", value: boolean): void;
}>();

const trigger = ref<HTMLElement | SVGElement | null>(null);
const dropdown = templateRef<HTMLDivElement>("dropdown", null);

const { bottom, left, right, width, update } = useElementBounding(trigger, {
  windowScroll: false,
});

const stopPropagation = ref<boolean>(false);

whenever(
  () => props.modelValue,
  () => update(),
);

useEventListener(
  document,
  "scroll",
  () => {
    if (props.modelValue) {
      window.requestAnimationFrame(update);
    }
  },
  { capture: true },
);

onClickOutside(dropdown, (ev) => {
  if (props.modelValue) {
    ev.stopPropagation();
    emit("update:modelValue", false);
  }
});

useEventListener(trigger, "click", () => {
  if (!props.modelValue) {
    emit("update:modelValue", true);
  }
});

// methods
function setRef(ref: Element | ComponentPublicInstance | null): void {
  trigger.value = ref as HTMLElement | SVGElement | null;
}
</script>
