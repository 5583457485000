import { Fn, createSharedComposable, useEventBus } from "@vueuse/core";

// state
import { toastKey } from "@/state/keys";

// types
import { ToastType, Toast } from "@/types/toast";

import { uuid } from "vue-uuid";

const useToastBus = createSharedComposable(() => useEventBus<Toast>(toastKey));

export function toast<T extends ToastType>(toast: {
  type: T;
  title: string;
  body?: string;
  duration?: number;
  delay?: number;
  onShow?: Fn;
  onClick?: Fn;
  onClose?: Fn;
}): void {
  const { emit } = useToastBus();

  emit({
    ...toast,
    key: "toast_" + uuid.v4(),
  });
}
