import type { Options } from "ky";
import { User } from "@/types/model/user";
import { api } from "@/util/fetch";

type EditableUserData = {
  first_name: string;
  preposition: string;
  last_name: string;
  phone_number: string;
};

export const fetchUserSelf = (options?: Options) =>
  api.get("user/self/", options).json<User>();

export const putUserSelf = (data: EditableUserData, options?: Options) =>
  api
    .put("user/self/", {
      ...(options ?? {}),
      json: {
        ...data,
        ...(options?.json ?? {}),
      },
    })
    .json<User>();

export const patchUserSelf = (
  data: Partial<EditableUserData>,
  options?: Options,
) =>
  api
    .put("user/self/", {
      ...(options ?? {}),
      json: {
        ...data,
        ...(options?.json ?? {}),
      },
    })
    .json<User>();
