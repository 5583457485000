import type { Options } from "ky";
import {
  AuthData,
  LoginData,
  MFABackupCodesData,
  MFALoginData,
  MFAMethodData,
  RefreshData,
} from "@/types/data/auth";
import { api } from "@/util/fetch";
import { DataWithPagination } from "@/types/common/data";

export const refreshAccessToken = (refreshToken: string, options?: Options) =>
  api
    .post("auth/token/refresh/", {
      ...(options ?? {}),
      json: { refresh: refreshToken, ...(options?.json ?? {}) },
      hooks: {
        beforeRequest: [],
        ...(options?.hooks ?? {}),
      },
    })
    .json<RefreshData>();

export const tryLogin = (
  username: string,
  password: string,
  options?: Options,
) =>
  api
    .post("auth/login/", {
      ...(options ?? {}),
      json: {
        username,
        password,
        ...(options?.json ?? {}),
      },
      hooks: {
        beforeRequest: [],
        ...(options?.hooks ?? {}),
      },
    })
    .json<LoginData | MFALoginData>();

export const loginMFACodes = (
  code: string,
  ephemeralToken: string,
  options?: Options,
) =>
  api
    .post("auth/login/code/", {
      ...(options ?? {}),
      json: {
        ephemeral_token: ephemeralToken,
        code,
        ...(options?.json ?? {}),
      },
      hooks: {
        beforeRequest: [],
        ...(options?.hooks ?? {}),
      },
    })
    .json<LoginData>();

export const requestPasswordReset = (email: string, options?: Options) =>
  api
    .post("auth/password/reset/", {
      ...(options ?? {}),
      json: {
        email,
        ...(options?.json ?? {}),
      },
      hooks: {
        beforeRequest: [],
        ...(options?.hooks ?? {}),
      },
    })
    .json<AuthData>();

export const confirmPasswordReset = (
  newPassword1: string,
  newPassword2: string,
  uid: string,
  token: string,
  options?: Options,
) =>
  api
    .post("auth/password/reset/confirm/", {
      ...(options ?? {}),
      json: {
        new_password1: newPassword1,
        new_password2: newPassword2,
        uid,
        token,
        ...(options?.json ?? {}),
      },
      hooks: {
        beforeRequest: [],
        ...(options?.hooks ?? {}),
      },
    })
    .json<AuthData>();

export const changePassword = (
  oldPassword: string,
  newPassword1: string,
  newPassword2: string,
  options?: Options,
) =>
  api
    .post("auth/password/change/", {
      ...(options ?? {}),
      json: {
        old_password: oldPassword,
        new_password1: newPassword1,
        new_password2: newPassword2,
        ...(options?.json ?? {}),
      },
    })
    .json<AuthData>();

// MFA OTP

export const requestMFAOTPActivation = (options?: Options) =>
  api.post("auth/app/activate/", options).json<AuthData>();

export const activateMFAOTP = (code: string, options?: Options) =>
  api
    .post("auth/app/activate/confirm/", {
      ...(options ?? {}),
      json: {
        code,
        ...(options?.json ?? {}),
      },
    })
    .json<MFABackupCodesData>();

export const deactivateMFAOTP = (code: string, options?: Options) =>
  api
    .post("auth/app/deactivate/", {
      ...(options ?? {}),
      json: {
        code,
        ...(options?.json ?? {}),
      },
    })
    .json<null>();

export const requestMFAEmailActivation = (options?: Options) =>
  api.post("auth/email/activate/", options).json<AuthData>();

export const activateMFAEmail = (code: string, options?: Options) =>
  api
    .post("auth/email/activate/confirm/", {
      ...(options ?? {}),
      json: {
        code,
        ...(options?.json ?? {}),
      },
    })
    .json<MFABackupCodesData>();

export const deactivateMFAEmail = (code: string, options?: Options) =>
  api
    .post("auth/email/deactivate/", {
      ...(options ?? {}),
      json: {
        code,
        ...(options?.json ?? {}),
      },
    })
    .json<null>();

export const requestMFAEmailVerificationCode = (options?: Options) =>
  api.post("auth/code/reqeust/", options).json<AuthData>();

export const fetchActiveMFAMethods = (options?: Options) =>
  api
    .get("auth/mfa/user-active-methods/", {
      cache: "no-cache",
      ...(options ?? {}),
    })
    .json<DataWithPagination<MFAMethodData>>();

export const changeActiveMFAMethod = (
  newMethod: "email" | "app",
  oldMethodCode: string,
  options?: Options,
) =>
  api
    .post("auth/mfa/change-primary-method/", {
      ...(options ?? {}),
      json: {
        method: newMethod,
        code: oldMethodCode,
        ...(options?.json ?? {}),
      },
    })
    .json<null>();
