import { createSharedComposable } from "@vueuse/core";
import { useSelector } from "@xstate/vue";
import { authMachine } from "./machines";
import { onScopeDispose } from "vue";
import { interpret } from "xstate";

export const useAuth = createSharedComposable(() => {
  const interpreter = interpret(authMachine, {
    devTools: import.meta.env.DEV,
  });

  const service = interpreter.start();

  const isStillChecking = useSelector(service, (emitted) =>
    emitted.matches("checkingIfLoggedIn"),
  );
  const isLoggedIn = useSelector(service, (emitted) =>
    emitted.matches("loggedIn"),
  );

  onScopeDispose(() => {
    service.stop();
  });

  return {
    isLoggedIn: isLoggedIn,
    isStillChecking: isStillChecking,
    send: service.send,
    service,
  };
});
