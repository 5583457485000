import { RouteRecordRaw } from "vue-router";

import CoreNavbar from "@/components/core/navbar.vue";
import PlaceholderNavbar from "@/components/placeholder/placeholderNavbar.vue";
import { toast } from "@/util/toast";

const toastInvalidUrl = () => toast({ type: "danger", title: "Invalid url" });

/*
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
export const routes: RouteRecordRaw[] = [
  {
    path: "/login/:pathMatch(.*)*",
    components: {
      default: () => import("../views/login/login.vue"),
      header: PlaceholderNavbar,
    },
    redirect: {
      name: "login",
    },

    children: [
      {
        path: "",
        component: () => import("../views/login/views/credentials.vue"),
        name: "login",
      },
      {
        path: "resetpassword",
        name: "resetPassword",

        components: {
          default: () =>
            import("../views/login/views/resetPasswordRequest.vue"),
          header: PlaceholderNavbar,
        },
      },
      {
        props: true,
        path: "resetpassword/confirm/:uid/:token",
        name: "confirmResetPassword",
        components: {
          default: () =>
            import("../views/login/views/resetPasswordConfirm.vue"),
          header: PlaceholderNavbar,
        },
      },
    ],
  },
  {
    path: "/user",
    redirect: "/user/self",
  },
  {
    path: "/user/self",
    name: "userSelf",
    components: {
      default: () => import("../views/user/userSelf.vue"),
      header: CoreNavbar,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/dossier/:dossierId",
    name: "dossier",
    props: true,
    components: {
      default: () => import("../views/dossier/dossier.vue"),
      header: CoreNavbar,
    },
    redirect: { name: "dossierEdit" },
    children: [
      {
        path: "edit",
        component: () => import("../views/dossier/views/edit.vue"),
        name: "dossierEdit",
      },
      ...(FEATURE_EDITOR
        ? ([
            {
              path: "document/:documentId",
              name: "dossierDocument",
              props: true,
              component: () => import("../views/dossier/views/document.vue"),
              beforeEnter: (to) => {
                if (
                  !to.params.documentId ||
                  to.params.documentId == "null" ||
                  to.params.documentId == "undefined"
                ) {
                  toastInvalidUrl();
                  return false;
                }

                return;
              },
            },
          ] satisfies RouteRecordRaw[])
        : []),
      {
        props: true,
        path: "pdf/:dossierActionId",
        component: () => import("../views/dossier/views/pdf.vue"),
        name: "dossierPdfViewer",
        beforeEnter: (to) => {
          if (
            !to.params.dossierActionId ||
            to.params.dossierActionId == "null" ||
            to.params.dossierActionId == "undefined"
          ) {
            toastInvalidUrl();
            return false;
          }

          return;
        },
      },
    ],
    beforeEnter: (to) => {
      if (
        !to.params.dossierId ||
        to.params.dossierId == "null" ||
        to.params.dossierId == "undefined"
      ) {
        toastInvalidUrl();
        return false;
      }

      return;
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/",
    name: "dossiers",
    components: {
      default: () => import("../views/dossiers.vue"),
      header: CoreNavbar,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/:catchAll(.+)",
    name: "notFound",
    redirect: "/",
  },
];
