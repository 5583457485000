import { Toast } from "@/types/toast";
import {
  ToastItemMachineEvent,
  ToastItemTypeState,
} from "@/types/stateMachines/toastItemMachine";
import { createMachine } from "xstate";

export const createToastItemMachine = (context: Toast) =>
  createMachine<Toast, ToastItemMachineEvent, ToastItemTypeState>(
    {
      id: "toast",
      initial: "ready",
      predictableActionArguments: true,
      context,
      states: {
        ready: {
          after: {
            TOAST_DELAY: { target: "showing" },
          },
        },
        showing: {
          entry: ["onShow"],
          initial: "waiting",
          states: {
            waiting: {
              on: {
                CLICKED: {
                  target: "clicked",
                },
                CLOSE: {
                  target: "#toast.closed",
                },
              },
              after: {
                TOAST_DURATION: { target: "#toast.closed" },
              },
            },
            clicked: {
              entry: ["onClicked"],
              always: "waiting",
            },
          },
        },
        closed: {
          entry: ["onClosed"],
        },
      },
    },
    {
      actions: {
        onShow: (ctx, _) => ctx.onShow && ctx.onShow(),
        onClicked: (ctx, _) => ctx.onClick && ctx.onClick(),
        onClosed: (ctx, _) => ctx.onClose && ctx.onClose(),
      },
      delays: {
        TOAST_DELAY: (ctx, _) => ctx.delay ?? 0,
        TOAST_DURATION: (ctx, _) => ctx.duration ?? 3000,
      },
    },
  );
