<template>
  <Teleport to="#toasts">
    <div
      class="flex flex-col gap-y-3 absolute -bottom-[96vh] left-1/2 -translate-x-1/3 h-max"
      v-if="state.context.toastRefs.length"
    >
      <TransitionGroup
        @before-enter="onBeforeEnter"
        @enter="onEnter"
        @before-leave="onBeforeLeave"
        @leave="onLeave"
      >
        <ToasterToast
          v-for="toastRef in filteredToastRefs"
          :key="toastRef.key"
          :toast-ref="toastRef.ref"
        />
      </TransitionGroup>
    </div>
  </Teleport>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { useEventBus } from "@vueuse/core";
import { toastKey } from "@/state/keys";

import ToasterToast from "@/components/toast.vue";
import { Toast } from "@/types/toast";

export default defineComponent({
  name: "ToastToaster",
});
</script>
<script lang="ts" setup>
import { gsap } from "gsap";
import { Expo } from "gsap";
import { useActor, useMachine } from "@xstate/vue";

import { computed } from "vue";

import { toastsMachine } from "@/state/machines/toastMachine";

const bus = useEventBus<Toast>(toastKey);

const { state, send } = useMachine(toastsMachine, {
  devTools: import.meta.env.DEV,
});

const filteredToastRefs = computed(() =>
  state.value.context.toastRefs.filter((toastRef) => {
    // TODO los dit op een beter manier op. Deze manier is dirty.
    const { state } = useActor(toastRef.ref);

    return state.value.matches("showing");
  }),
);

bus.on((payload) => {
  send({
    type: "TOAST.CREATE",
    payload: Object.assign({}, payload, {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      onClick: payload.onClick ? () => payload.onClick!() : undefined,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      onClose: payload.onClose ? () => payload.onClose!() : undefined,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      onShow: payload.onShow ? () => payload.onShow!() : undefined,
    } as Toast),
  });
});

function onBeforeEnter(el: Element) {
  gsap.set(el, {
    opacity: 0,
  });
}

function onEnter(el: Element, done: () => void) {
  gsap.to(el, {
    opacity: 100,

    duration: 0.5,
    ease: Expo.easeIn,

    onComplete: done,
  });
}

function onBeforeLeave(el: Element) {
  const htmlEl = el as HTMLElement;
  htmlEl.style.minWidth = htmlEl.clientWidth.toString() + "px";
}

function onLeave(el: Element, done: () => void) {
  const htmlEl = el as HTMLElement;

  gsap.to(el, {
    opacity: 0,

    duration: 0.3,
    ease: Expo.easeOut,

    onComplete: () => {
      send({ type: "TOAST.CLOSED", payload: htmlEl.dataset.key ?? "" });
      done();
    },
  });
}
</script>
