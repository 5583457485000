/**
 * Vue Router
 *
 * @library
 *
 * https://router.vuejs.org/en/
 */

// Lib imports
import { useGlobalServices } from "@/state";
import {
  Router,
  createRouter as createVueRouter,
  createWebHistory,
} from "vue-router";

import { defineGaurds } from "./guards";

// Routes
import { routes } from "./routes";

export function createRouter(): Router {
  // Create a new router
  const router = createVueRouter({
    history: createWebHistory(BASE_URL),
    routes,
    scrollBehavior: (to, _, savedPosition) => {
      if (to.hash)
        return {
          behavior: "smooth",
          el: to.hash,
        };

      if (savedPosition)
        return {
          ...savedPosition,
          behavior: savedPosition.behavior ?? "auto",
        };

      return {
        behavior: "auto",
        top: 0,
        left: 0,
      };
    },
  });

  defineGaurds(router);

  router.isReady().then(() => {
    useGlobalServices();
  });

  return router;
}
