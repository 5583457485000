import { LoginData, MFALoginData } from "@/types/data/auth";
import {
  LoginMachineContext,
  LoginMachineEvent,
} from "@/types/stateMachines/loginMachine";
import { loginMFACodes, tryLogin } from "@/util/fetch/endpoints/authEndpoints";
import { toast } from "@/util/toast";
import { createMachine, DoneInvokeEvent, assign } from "xstate";

export const loginMachine = createMachine<
  LoginMachineContext,
  LoginMachineEvent
>(
  {
    id: "auth-login",
    predictableActionArguments: true,

    schema: {
      context: {} as LoginMachineContext,
      events: {} as LoginMachineEvent,
    },

    context: {
      method: "none",
      ephemeralToken: null,
    },

    initial: "idle",
    states: {
      idle: {
        entry: ["clearContext"],
        on: {
          LOG_IN: {
            target: "processing",
          },
        },
      },
      processing: {
        type: "compound",

        initial: "tryLogin",

        states: {
          tryLogin: {
            description:
              "Try to login and see if there is two factor authentication",

            invoke: {
              id: "auth-login-try",
              src: (_, event) =>
                (async (): Promise<LoginData | MFALoginData> => {
                  if (event.type !== "LOG_IN") {
                    throw new Error(
                      "Unexpected event type in 'auth-login-try' service. Expected 'LOG_IN'",
                    );
                  }

                  return tryLogin(
                    event.payload.username,
                    event.payload.password,
                  );
                })(),
              onDone: [
                {
                  cond: (_, event: DoneInvokeEvent<LoginData | MFALoginData>) =>
                    "access" in event.data,
                  target: "#success",
                },
                {
                  target: "twofactor",
                  actions: assign(
                    (_, event: DoneInvokeEvent<MFALoginData>) => ({
                      ephemeralToken: event.data.ephemeralToken,
                      method: event.data.method,
                    }),
                  ),
                },
              ],
              onError: {
                target: "#invalid",
                actions: (_, event) => {
                  console.error(
                    new Error("onError from 'auth-login-token' service", {
                      cause: event.data,
                    }),
                  );
                  toast({
                    type: "danger",
                    title: "invalid credentials",
                  });
                },
              },
            },
          },
          twofactor: {
            description: "Process twofactor codes and login",

            type: "compound",
            initial: "awaitInput",
            states: {
              awaitInput: {
                description: "Wait for user to input two factor code",

                on: {
                  TWO_FACTOR_INPUT: "posting",
                },
              },
              posting: {
                invoke: {
                  id: "auth-login-mfa-code",
                  src: async (ctx, event) => {
                    if (event.type !== "TWO_FACTOR_INPUT") {
                      throw new Error(
                        "Unexpected event type in 'auth-login-token' service. Expected 'TWO_FACTOR_INPUT'",
                      );
                    }

                    return loginMFACodes(
                      event.payload,
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      ctx.ephemeralToken!,
                    );
                  },
                  onDone: {
                    target: "#success",
                  },
                  onError: {
                    target: "invalid",
                    actions: (_, event) => {
                      console.error(
                        new Error(
                          "onError from 'auth-login-mfa-code' service",
                          {
                            cause: event.data,
                          },
                        ),
                      );
                      toast({
                        type: "danger",
                        title: "invalid Two-factor code",
                      });
                    },
                  },
                },
              },
              invalid: {
                on: {
                  TWO_FACTOR_INPUT: "posting",
                },
              },
            },
          },
        },
      },
      invalid: {
        id: "invalid",
        entry: "clearContext",

        on: {
          LOG_IN: {
            target: "processing",
          },
        },
      },

      succes: {
        id: "success",
        type: "final",
        data: (
          _,
          event: DoneInvokeEvent<{
            accessToken: string;
            refreshToken: string;
          }>,
        ) => event.data,
      },
    },
  },
  {
    actions: {
      clearContext: assign({
        ephemeralToken: null,
        method: "none",
      }),
    },
  },
);
