import { assign, createMachine, DoneInvokeEvent, forwardTo } from "xstate";

import {
  AuthMachineContext,
  AuthMachineEvent,
} from "@/types/stateMachines/authMachine";

import { jwtDecode, JwtPayload } from "jwt-decode";
import {
  accessToken,
  accessTokenExpireDate,
  refreshToken,
  refreshTokenExpireDate,
} from "@/util/storageHelper";
import { LoginData, RefreshData } from "@/types/data/auth";
import { loginMachine } from "@/state/machines/loginMachine";
import { refreshAccessToken } from "@/util/fetch/endpoints";

function getTimestampFromToken(token: string) {
  let expireDate = jwtDecode<JwtPayload>(token).exp;

  if (!expireDate) {
    throw new Error("Decoded expireDate from access token is invalid");
  }

  expireDate *= 1000;

  return expireDate;
}

export const authMachine = createMachine<AuthMachineContext, AuthMachineEvent>(
  {
    id: "auth",
    predictableActionArguments: true,

    schema: {
      context: {} as AuthMachineContext,
      events: {} as AuthMachineEvent,
    },

    context: {
      accessToken: accessToken.get(),
      accessTokenExpireDate: accessTokenExpireDate.get(),
      refreshToken: refreshToken.get(),
      refreshTokenExpireDate: refreshTokenExpireDate.get(),
    },

    initial: "checkingIfLoggedIn",
    states: {
      checkingIfLoggedIn: {
        invoke: {
          id: "auth-check-if-logged-in",
          src: (ctx) => (send) => {
            if (
              !ctx.accessToken ||
              ctx.accessTokenExpireDate === null ||
              ctx.accessTokenExpireDate < Date.now()
            ) {
              send("REPORT_IS_LOGGED_OUT");
            } else {
              send("REPORT_IS_LOGGED_IN");
            }
          },
        },

        on: {
          REPORT_IS_LOGGED_OUT: "loggedOut",
          REPORT_IS_LOGGED_IN: "loggedIn",
        },
      },
      loggedIn: {
        type: "compound",

        on: {
          LOG_OUT: "loggedOut",
        },

        initial: "polling",
        states: {
          polling: {
            invoke: {
              id: "auth-poll-token-expire-date-check",
              src: (ctx) => (send) => {
                // This will send the 'INC' event to the parent every second
                const id = setInterval(() => {
                  const now = Date.now();
                  if (
                    !ctx.accessToken ||
                    ctx.accessTokenExpireDate === null ||
                    ctx.accessTokenExpireDate < now
                  ) {
                    if (
                      !ctx.refreshToken ||
                      ctx.refreshTokenExpireDate === null ||
                      ctx.refreshTokenExpireDate < now
                    ) {
                      send("LOG_OUT");
                    } else {
                      send("REFRESH");
                    }
                  }
                }, 31000);

                // Perform cleanup
                return () => clearInterval(id);
              },
            },
            on: {
              REFRESH: "refreshing",
            },
          },
          refreshing: {
            entry: [
              assign({
                accessToken: null,
                accessTokenExpireDate: null,
              }),
              () => {
                accessToken.clear();
                accessTokenExpireDate.clear();
              },
            ],

            invoke: {
              id: "auth-refresh-token",
              src: async (ctx): Promise<RefreshData> => {
                if (!ctx.refreshToken) throw Error("refreshToken is null!");

                return refreshAccessToken(ctx.refreshToken);
              },
              onDone: {
                target: "polling",
                actions: [
                  assign<AuthMachineContext, DoneInvokeEvent<RefreshData>>({
                    accessToken: (_, event) => event.data.access,
                    accessTokenExpireDate: (_, event) =>
                      getTimestampFromToken(event.data.access),
                  }),
                  (_, event: DoneInvokeEvent<RefreshData>) => {
                    accessToken.set(event.data.access);
                    accessTokenExpireDate.set(
                      getTimestampFromToken(event.data.access),
                    );
                  },
                ],
              },
              onError: {
                target: "#auth.loggedOut",
                actions: (_, event) => {
                  console.error(
                    new Error("Error occured on token refresh", {
                      cause: event.data,
                    }),
                  );
                },
              },
            },
          },
        },
      },
      loggedOut: {
        entry: ["clearContext", "clearStorage"],

        invoke: {
          id: "auth-login",
          src: loginMachine,
          onDone: {
            target: "loggedIn",
            actions: [
              assign<AuthMachineContext, DoneInvokeEvent<LoginData>>({
                accessToken: (_, event) => event.data.access,
                accessTokenExpireDate: (_, event) =>
                  getTimestampFromToken(event.data.access),
                refreshToken: (_, event) => event.data.refresh,
                refreshTokenExpireDate: (_, event) =>
                  getTimestampFromToken(event.data.refresh),
              }),
              (_, event: DoneInvokeEvent<LoginData>) => {
                accessToken.set(event.data.access);
                accessTokenExpireDate.set(
                  getTimestampFromToken(event.data.access),
                );
                refreshToken.set(event.data.refresh);
                refreshTokenExpireDate.set(
                  getTimestampFromToken(event.data.refresh),
                );
              },
            ],
          },
        },

        on: {
          LOG_IN: {
            actions: forwardTo("auth-login"),
          },
        },
      },
    },
  },
  {
    actions: {
      clearContext: assign({
        accessToken: null,
        accessTokenExpireDate: null,
        refreshToken: null,
        refreshTokenExpireDate: null,
      }),
      clearStorage: () => {
        accessToken.clear();
        accessTokenExpireDate.clear();
        refreshToken.clear();
        refreshTokenExpireDate.clear();
      },
    },
  },
);
