import { computedAsync, createSharedComposable } from "@vueuse/core";

// types
import { User } from "@/types/model/user";

// state
import { useAuth } from "@/state/auth";

// util
import { fetchActiveMFAMethods } from "@/util/fetch/endpoints/authEndpoints";
import { fetchUserSelf } from "@/util/fetch/endpoints/userEndpoints";
import { MFAMethodData } from "@/types/data/auth";
import { Ref, ref, watch } from "vue";

export const useMFA = createSharedComposable(() => {
  const auth = useAuth();
  const mfa = computedAsync<MFAMethodData[]>((onCancel) => {
    const controller = new AbortController();
    onCancel(() => controller.abort());

    if (!auth.isLoggedIn.value) return [];

    return fetchActiveMFAMethods({
      signal: controller.signal,
    }).then((data) => data.results);
  });

  return mfa;
});

export const useUser = createSharedComposable<() => Ref<User | null>>(() => {
  const auth = useAuth();
  const userData = computedAsync((onCancel) => {
    const controller = new AbortController();
    onCancel(() => controller.abort());

    if (!auth.isLoggedIn.value) return null;

    return fetchUserSelf().catch(() => null);
  }, null);

  const user = ref<User | null>(null);

  watch(userData, (userData) => {
    user.value = userData;
  });

  return user;
});
