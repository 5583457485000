<template>
  <div
    class="flex flex-nowrap justify-between items-center py-1 pl-1.5 pr-1 bg-white border rounded-md shadow-md transition-[width] ease-in dureation-150"
    role="alert"
    @click="onClick()"
  >
    <div class="">
      <div class="flex flex-nowrap h-max items-center space-x-2">
        <div class="iconContainer mb-auto">
          <InformationCircleIcon
            v-if="toast.type === 'info'"
            class="w-6 h-6 fill-info flex-shrink-0"
          />
          <CheckCircleIcon
            v-else-if="toast.type === 'success'"
            class="w-6 h-6 fill-success flex-shrink-0"
          />
          <ExclamationCircleIcon
            v-else-if="toast.type === 'danger'"
            class="w-6 h-6 fill-danger flex-shrink-0"
          />
        </div>
        <template v-if="toast.title || toast.body">
          <h5
            class="text-gray-800 font-semibold text-ellipsis overflow-hidden"
            v-if="toast.title && toast.body"
          >
            {{ toast.title }}
          </h5>
          <p v-else class="text-sm text-ellipsis overflow-hidden">
            {{ toast.title || toast.body }}
          </p>
        </template>
      </div>

      <p v-if="toast.title && toast.body" class="text-sm ml-8 text-ellipsis">
        {{ toast.body }}
      </p>
    </div>
    <button
      type="button"
      class="button button-xs button-ghost !min-w-0 !p-0.5 ml-1.5 text-stroke mb-auto"
      @click="onCloseBtnClick()"
    >
      <!-- <span class="sr-only">Close Alert</span> -->
      <div class="iconContainer">
        <XMarkIcon class="w-6 h-6"></XMarkIcon>
      </div>
    </button>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent } from "vue";
import { ActorRef } from "xstate";

export default defineComponent({
  name: "ToasterToast",
});
</script>
<script lang="ts" setup>
import { ToastItemMachineEvent } from "@/types/stateMachines/toastItemMachine";

import {
  CheckCircleIcon,
  InformationCircleIcon,
  ExclamationCircleIcon,
  XMarkIcon,
} from "@heroicons/vue/24/solid";

import { useActor } from "@xstate/vue";

const props = defineProps<{
  toastRef: ActorRef<ToastItemMachineEvent>;
}>();

const { state, send } = useActor(props.toastRef);

const toast = computed(() => state.value.context);

function onClick(): void {
  send({ type: "CLICKED" });
}

function onCloseBtnClick(): void {
  send({ type: "CLOSE" });
}
</script>
