import { createApp } from "vue";
import { inspect } from "@xstate/inspect";
import { APP_VERSION } from "@/util/env";
import * as Sentry from "@sentry/vue";

if (import.meta.env.DEV) {
  inspect({
    iframe: false,
  });
}

// Plugins
import { createPinia } from "pinia";
import withUUID from "vue-uuid";
// TODO reimplement translations
import { createRouter } from "./router";

// create plugins
const pinia = createPinia();
const router = createRouter();

// css
import "./styles/index.css";

import App from "./app.vue";

const app = withUUID(createApp(App));

console.log("v" + APP_VERSION);

if (import.meta.env.PROD) {
  if (SENTRY) {
    Sentry.init({
      app,
      dsn: SENTRY_DSN,
      release: SENTRY_RELEASE,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.25,

      environment: SENTRY_ENVIRONMENT,

      autoSessionTracking: true,

      trackComponents: true,

      transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
    });
  }
}

// add plugins to the vue instance
app.use(router);
app.use(pinia);

document.addEventListener("readystatechange", () => {
  if (document.readyState == "complete") {
    const zendesk_iframe = document.querySelector<HTMLIFrameElement>(
      "iframe[style*='-widgetOnLoad']",
    );

    if (zendesk_iframe) {
      zendesk_iframe.classList.add("!bottom-9", "!shadow-xl");
    }
  }
});

app.mount("#app");
