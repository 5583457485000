import { assign, createMachine, spawn } from "xstate";
import {
  ToastMachineContext,
  ToastMachineEvent,
  ToastTypeState,
} from "@/types/stateMachines/toastMachine";
import { createToastItemMachine } from "./toastItemMachine";

export const toastsMachine = createMachine<
  ToastMachineContext,
  ToastMachineEvent,
  ToastTypeState
>({
  id: "toasts",
  predictableActionArguments: true,
  initial: "active",

  context: {
    toastRefs: [],
  },
  states: {
    active: {},
  },
  on: {
    "TOAST.CREATE": {
      target: "active",
      actions: assign({
        toastRefs: (ctx, event) => [
          ...ctx.toastRefs,
          {
            key: event.payload.key,
            ref: spawn(createToastItemMachine(event.payload)),
          },
        ],
      }),
      internal: true,
    },
    "TOAST.CLOSED": {
      actions: assign({
        toastRefs: (ctx, event) => {
          const toastRef = ctx.toastRefs.find(
            (toastRef) => toastRef.key === event.payload,
          );

          toastRef?.ref.stop?.();

          return ctx.toastRefs.filter(
            (toastRef) => toastRef.key !== event.payload,
          );
        },
      }),
    },
  },
});
